import * as React from 'react';

import { Domain } from 'api';
import { useGettext, getCountryCodeFromLocale, localizeLanguageLabel } from 'data-store';
import { Tx } from 'ui-components';

import CountryLabel from './CountryLabel';

export default function LanguageLabel(props: { locale: Domain.Locale; withCountry?: boolean }) {
    const { gettext } = useGettext();

    const languageLabel = localizeLanguageLabel(props.locale, gettext);

    return (
        <span>
            {languageLabel}
            {props.withCountry ? (
                <React.Fragment>
                    &nbsp;
                    <Tx level="body-sm">
                        (<CountryLabel country={getCountryCodeFromLocale(props.locale)} />)
                    </Tx>
                </React.Fragment>
            ) : null}
        </span>
    );
}
