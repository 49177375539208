import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { productSelectionApi } from '@/api';

export type ProductSelectionOverviewSearchProviderItem = Domain.SelectedProduct & {
    value: string;
    label: React.ReactNode;
    labelForBlock: React.ReactNode;
    price: Domain.Price;
};

interface ProductMapperInfo {
    stockLabel: string;
    robotStockLabel?: string;
    lockerStockLabel?: string;
}

type ProductMapper = (
    item: Domain.SelectedProduct,
    locale: Domain.Locale,
    info: ProductMapperInfo,
) => ProductSelectionOverviewSearchProviderItem;

export class ProductSelectionOverviewSearchProvider implements ISearchProvider<ProductSelectionOverviewSearchProviderItem> {
    private rootCategoryId: Domain.ProductCategory['categoryId'] | undefined;
    private selectionType: Domain.ProductSelectionType;
    private productCompleteness: Domain.ProductCompletenessFilter;
    private locale: Domain.Locale;
    private productMapperInfo: ProductMapperInfo;
    private productMapper: ProductMapper;
    private forBranchId: false | Domain.Branch['branchId'] = false;
    private hasMoreResults = false;

    private searchCache: {
        [key: string]: Promise<Domain.SelectedProductsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.SelectedProduct>;
    } = {};

    constructor(productMapper: ProductMapper) {
        this.productMapper = productMapper;
        this.productMapperInfo = {
            stockLabel: ':quantity:',
            robotStockLabel: ':quantity:',
            lockerStockLabel: ':quantity:',
        };
    }

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    public setProductMapperInfo(info: ProductMapperInfo) {
        this.productMapperInfo = info;
    }

    public setRootCategoryId(rootCategoryId: Domain.ProductCategory['categoryId'] | undefined) {
        if (this.rootCategoryId !== rootCategoryId) {
            this.rootCategoryId = rootCategoryId;
            this.resetCache();
        }
    }

    public setLocale(locale: Domain.Locale) {
        if (this.locale !== locale) {
            this.locale = locale;
            this.resetCache();
        }
    }

    public setSelectionType(selectionType: Domain.ProductSelectionType) {
        if (this.selectionType !== selectionType) {
            this.selectionType = selectionType;
            this.resetCache();
        }
    }

    public setProductCompleteness(productCompleteness: Domain.ProductCompletenessFilter) {
        if (this.productCompleteness !== productCompleteness) {
            this.productCompleteness = productCompleteness;
            this.resetCache();
        }
    }

    public setForBranchId(forBranchId: false | Domain.Branch['branchId']) {
        if (this.forBranchId !== forBranchId) {
            this.forBranchId = forBranchId;
            this.resetCache();
        }
    }

    public setProductMapper(productMapper: ProductMapper) {
        this.productMapper = productMapper;
    }

    async preloadByValues(values: string[]) {
        if (!this.forBranchId) {
            throw new Error('BranchId not set');
        }

        const valuesToLoad = values.filter(value => !this.byValueCache.hasOwnProperty(value));

        if (valuesToLoad.length > 0) {
            const products = await productSelectionApi.GetSelectedProducts(
                {
                    type: 'branch',
                    ownerId: this.forBranchId,
                },
                this.selectionType,
                { page: 1, size: 999 },
                this.locale,
                undefined,
                this.productCompleteness,
                undefined,
                {
                    productIds: values.join(','),
                },
            );

            for (const item of products.items) {
                this.byValueCache[item.productId] = Promise.resolve(item);
            }
        }
    }

    async search(query: string) {
        if (!this.forBranchId) {
            throw new Error('BranchId not set');
        }

        if (!this.searchCache[query]) {
            this.searchCache[query] = productSelectionApi.GetSelectedProducts(
                {
                    type: 'branch',
                    ownerId: this.forBranchId,
                },
                this.selectionType,
                { page: 1, size: 10 },
                this.locale,
                query,
                this.productCompleteness,
                undefined,
                {
                    categoryIds: this.rootCategoryId,
                },
            );
        }

        const page = await this.searchCache[query];

        for (const item of page.items) {
            this.byValueCache[item.productId] = Promise.resolve(item);
        }

        return page.items.map(this.mapItem);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.forBranchId) {
            throw new Error('BranchId not set');
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = productSelectionApi.GetSelectedProductDetails(
                {
                    type: 'branch',
                    ownerId: this.forBranchId,
                },
                value,
                this.selectionType,
                this.locale,
            );
        }

        const item = await this.byValueCache[value];
        return this.mapItem(item);
    }

    async byValues(values: string[]) {
        return Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<ProductSelectionOverviewSearchProviderItem>));
    }

    private mapItem = (item: Domain.SelectedProduct): ProductSelectionOverviewSearchProviderItem => {
        return this.productMapper(item, this.locale, this.productMapperInfo);
    };
}
