import * as React from 'react';

import { Button, ButtonSize } from '@/button';
import { Card } from '@/card';
import { Checkbox } from '@/checkbox';
import { Dropdown, SortableList } from '@/core';
import { Icon } from '@/icon';
import { Container, Row, Col } from '@/layout';

import styles from './ManageColumns.scss';
export default function ManageColumns(props: {
    className?: string;
    onApply?: (selectedCategoryIds: string | undefined) => Promise<void>;
    label?: React.ReactNode;
    buttonSize?: ButtonSize;
    gettext: (text: string) => string;
    onVisibleColumnsChange: (visibleColumns: string[]) => void;
    onSortableColumnsChange: (sortableColumns: string[]) => void;
    sortableColumns: string[];
    visibleColumns: string[];
    toggleColumnsOptions: {
        label: React.ReactNode;
        value: any;
    }[];
}) {
    const dropdown = React.useRef<Dropdown>(null);

    return (
        <Dropdown
            ref={dropdown as any}
            className={props.className}
            bodyClassName="pt-10"
            portalClassName="FixedCustom"
            headerRenderer={isOpen => {
                return (
                    <Button
                        variant="tertiary"
                        variantSize={props.buttonSize || 's'}
                        data-test-id="manage-columns-button"
                        startIcon={<Icon type="action_column" />}
                        endIcon={
                            <Icon
                                type={isOpen ? 'action_arrow_up' : 'action_arrow_down'}
                                iconSize="m"
                            />
                        }
                    >
                        {props.gettext('Manage columns')}
                    </Button>
                );
            }}
            body={
                <Card
                    hSpacing="none"
                    vSpacing="none"
                    elevated={true}
                >
                    <SortableList
                        items={props.toggleColumnsOptions
                            .sort((a, b) => props.sortableColumns.indexOf(a.value) - props.sortableColumns.indexOf(b.value))
                            .map(column => {
                                return (
                                    <Container key={column.value}>
                                        <Row className={styles.ManageColumnRow}>
                                            <Col>
                                                <Checkbox
                                                    data-test-id={`column-${column.value}`}
                                                    value={column.value}
                                                    checked={props.visibleColumns.includes(column.value)}
                                                    onChange={checked => {
                                                        const newVisibleColumns = checked
                                                            ? [...props.visibleColumns, column.value]
                                                            : props.visibleColumns.filter(c => c !== column.value);
                                                        props.onVisibleColumnsChange(newVisibleColumns);
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: '16px',
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            maxWidth: '180px',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        {column.label}
                                                    </span>
                                                </Checkbox>
                                            </Col>
                                        </Row>
                                    </Container>
                                );
                            })}
                        direction="vertical"
                        itemSize={38}
                        onItemMove={(itemIndex: number, moveOffset: number) => {
                            const newOrderedColumn = [...props.toggleColumnsOptions];
                            const itemToMove = newOrderedColumn.splice(itemIndex, 1)[0];
                            newOrderedColumn.splice(itemIndex + moveOffset, 0, itemToMove);
                            props.onSortableColumnsChange(newOrderedColumn.map(c => c.value));
                        }}
                    />
                </Card>
            }
        />
    );
}
