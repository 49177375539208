import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { I18n, maybeSelectLoggedInUser, useGettext } from 'data-store';
import { Button, Card, Dropdown, Icon } from 'ui-components';

import styles from './HeaderMenu.scss';

export default function HelpDropdown(props: { className?: string }) {
    const { gettext } = useGettext();
    const navigate = useNavigate();
    const selectedLocale = useSelector(I18n.selectCurrentLocale);
    const user = useSelector(maybeSelectLoggedInUser);

    let url = 'https://www.lochting.com/en/faq/';

    switch (selectedLocale) {
        case 'nl_BE':
            url = 'https://www.lochting.com/nl/faq/';
            break;
        case 'fr_BE':
            url = 'https://www.lochting.com/fr-be/faq/';
            break;
        case 'fr_FR':
            url = 'https://www.lochting.com/fr/faq/';
            break;
    }

    return (
        <Dropdown
            className={props.className}
            anchorRight={true}
            headerRenderer={() => (
                <Button
                    variant="secondary"
                    variantSize="xs"
                    startIcon={<Icon type="nav_curl" />}
                >
                    <span className="hidden-sm">{gettext('Support')}</span>
                </Button>
            )}
            body={
                <React.Fragment>
                    <Card
                        hSpacing="none"
                        vSpacing="none"
                        elevated={true}
                        className="mt-7"
                    >
                        {user ? (
                            <a
                                className={styles.MenuAnchor}
                                onClick={() => navigate('/contact')}
                                title={gettext('Contact')}
                            >
                                {gettext('Contact')}
                            </a>
                        ) : null}
                        <a
                            className={styles.MenuAnchor}
                            href={url}
                            target="_blank"
                            title={gettext('FAQ')}
                        >
                            {gettext('FAQ')}
                        </a>
                        {/* <a className={styles.MenuAnchor} onClick={() => navigate('/terms-and-conditions')}>
                            {gettext('Terms and conditions')}
                        </a> */}
                        <a
                            className={styles.MenuAnchor}
                            onClick={() => navigate('/privacy-policy')}
                        >
                            {gettext('Privacy policy')}
                        </a>
                    </Card>
                </React.Fragment>
            }
        />
    );
}
