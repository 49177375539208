import * as React from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

import { maybeSelectLoggedInUser, useGettext } from 'data-store';
import { Button, Card, Dropdown, Icon } from 'ui-components';

function getTimezoneOffset(timezone: string) {
    const now = moment.tz(timezone);
    const offsetMinutes = now.utcOffset();
    const offsetHours = Math.floor(offsetMinutes / 60);
    const formattedOffset = (offsetHours >= 0 ? '+' : '') + offsetHours;

    return formattedOffset;
}

function displayTimezoneOffset(timezone: string) {
    const offset = getTimezoneOffset(timezone);
    return offset;
}

export default function TimezoneDropdown(props: { className?: string }) {
    const { gettext } = useGettext();
    const user = useSelector(maybeSelectLoggedInUser);

    if (!user) {
        return null;
    }

    return (
        <Dropdown
            className={props.className}
            anchorRight={true}
            headerRenderer={() => (
                <Button
                    variant="secondary"
                    variantSize="xs"
                    startIcon={<Icon type="nav_localisation" />}
                />
            )}
            body={
                <React.Fragment>
                    <Card
                        elevated={true}
                        className="mt-7"
                    >
                        {user ? (
                            <span>
                                {gettext('Current timezone: UTC')} {displayTimezoneOffset(user.branchTimezone || '')}
                            </span>
                        ) : null}
                    </Card>
                </React.Fragment>
            }
        />
    );
}
