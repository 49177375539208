import * as React from 'react';

import { ISearchProvider } from 'utils';

export default function DataTablePillLabel(props: {
    searchProvider?: ISearchProvider<{
        value: string;
        label: React.ReactNode;
    }>;
    item: string | undefined;
    labelRenderer?: (value: string) => React.ReactNode | string;
}) {
    const [labels, setLabels] = React.useState<React.ReactNode[]>([]);

    const refresh = async () => {
        if (props.searchProvider) {
            if (props.item) {
                const multipleItems = props.item.split(',');

                if (multipleItems.length > 1) {
                    const items: React.ReactNode[] = [];
                    const itemsData = await props.searchProvider.byValues(multipleItems);

                    itemsData.map(async multipleItem => {
                        if (multipleItem.label) {
                            items.push(multipleItem.label);
                        }
                    });
                    setLabels(items);
                } else {
                    const item = await props.searchProvider.byValue(props.item);
                    if (item) {
                        setLabels([item.label]);
                    }
                }
            }
        } else if (props.labelRenderer) {
            setLabels([props.labelRenderer(props.item || '')]);
        } else {
            setLabels([props.item || '']);
        }
    };

    React.useEffect(() => {
        refresh();
    }, [props.item]);

    return (
        <React.Fragment>
            {labels ? (
                <span>
                    {labels.map((label, index) => {
                        return (
                            <React.Fragment key={index}>
                                <span>{label}</span>
                                {index !== labels.length - 1 ? <span>, </span> : null}
                            </React.Fragment>
                        );
                    })}
                </span>
            ) : null}
        </React.Fragment>
    );
}
