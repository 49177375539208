import * as React from 'react';

import { Preloader } from 'ui-components';

export default function Breadcrumbs() {
    return (
        <Preloader
            width={90}
            varyWidth={240}
            height={11}
            className="mt-7"
        />
    );
}
