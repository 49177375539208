import * as React from 'react';

import styles from './LabelWithPills.scss';

export default function LabelWithPills(props: {
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    pills: React.ReactNode;
    alignRight?: boolean;
}) {
    return (
        <div
            className={
                styles.LabelWithPills +
                ' ' +
                (props.className || '') +
                ' ' +
                (!props.pills ? styles.NoPills : '') +
                ' ' +
                (props.alignRight ? styles.AlignRight : '')
            }
            style={props.style}
        >
            {props.children}
            {props.pills ? <div className={styles.LabelWithPillsPills}>{props.pills}</div> : null}
        </div>
    );
}
