import * as React from 'react';

import classNames from 'classnames';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { Button } from '@/button';
import { Card } from '@/card';
import color from '@/color';
import { Icon } from '@/icon';
import { Col, Container, Row, RowValign } from '@/layout';
import { Pagination, PaginationProps } from '@/pagination';
import { Preloader } from '@/preloader';
import { ISelectOption } from '@/select';
import { Tooltip } from '@/tooltip';

import DataGrid from './DataGrid';
import DataTableFilterGizmo from './DataTableFilterGizmo';
import DataTableFilterPills, { FiltersConfig } from './DataTableFilterPills';
import DataTableSortGizmo from './DataTableSortGizmo';
import ManageColumns from './ManageColumns';
import Table, { TableHeaderItem } from './Table';

import styles from './DataTable.scss';

type KeyOrNothing<K> = K extends string ? K : never;

export type DataTableColumn<T, P = void> = TableHeaderItem & {
    key: KeyOrNothing<P> | keyof T;
    render: (item: T) => React.ReactNode;
    filterClassName?: string;
    plainTextLabel?: string;
    sortable?: boolean;
    filterable?: true;
    filterOptions?: ISelectOption[];
    filterOptionsPlaceholder?: string;
    filterSearchProvider?: ISearchProvider<{
        value: string;
        label: React.ReactNode;
    }>;
    filterSearchPlaceholder?: string;
    customFilter?: React.ReactNode;
    customSorting?: React.ReactNode;
    toggleable?: boolean;
    multiSelect?: boolean;
    dateTime?: string;
    alignRight?: boolean;
    preventClickDefault?: boolean;
    customRemoveFilter?: () => void;
};

interface withoutColumnManager<T, P> {
    showColumnManager?: false;
    visibleColumns?: (KeyOrNothing<P> | keyof T)[];
    sortableColumns?: (KeyOrNothing<P> | keyof T)[];
    onVisibleColumnsChange?: (newVisibleColumns: (KeyOrNothing<P> | keyof T)[]) => void;
    onSortableColumnsChange?: (newSortableColumns: (KeyOrNothing<P> | keyof T)[]) => void;
}
interface withColumnManager<T, P> {
    showColumnManager: true;
    visibleColumns: (KeyOrNothing<P> | keyof T)[] | undefined;
    sortableColumns: (KeyOrNothing<P> | keyof T)[] | undefined;
    onVisibleColumnsChange: (newVisibleColumns: (KeyOrNothing<P> | keyof T)[]) => void;
    onSortableColumnsChange: (newSortableColumns: (KeyOrNothing<P> | keyof T)[]) => void;
}

interface BaseProps<T, P = void> {
    className?: string;
    cardClassName?: string;
    gettext: (text: string) => string;
    columns: (DataTableColumn<T, P> | false)[];
    data: T[];
    getItemKey: (item: T, index: number) => string | number;
    getItemDataTestId?: (item: T, index: number) => string;
    onSortingChange?: (newSorting: Domain.Sorting<KeyOrNothing<P> | keyof T>) => void;
    alignToggleColumnsToLeft?: boolean;
    topInfo?: React.ReactNode;
    extraTopActions?: React.ReactNode;
    extraTopActionsAreCols?: boolean;
    topActionsVAlign?: RowValign;
    rowsVAlign?: RowValign;
    sorting?: Domain.Sorting<KeyOrNothing<P> | keyof T>;
    onSortableColumnsChange?: (newSortableColumns: (KeyOrNothing<P> | keyof T)[]) => void;
    pagination?: PaginationProps;
    itemIsActive?: (item: T, index: number) => boolean;
    isLoading?: boolean;
    extraItemClassFn?: (item: T, index: number) => string;
    showToggleViewMode?: boolean;
    viewMode?: Domain.ViewMode;
    visibleOverflow?: boolean;
    setViewMode?: (viewMode: Domain.ViewMode) => void;
    gridItemRenderer?: (item: T) => React.ReactNode;
    filters?: {
        [key in KeyOrNothing<P> | keyof T]?: string | undefined;
    };
    onFiltersChange?: (newFilters: {
        [key in KeyOrNothing<P> | keyof T]?: string | undefined;
    }) => void;
    showFilterPills?: boolean | FiltersConfig<T, P>;
    onRowEnter?: (item: T, index: number) => void;
    onRowLeave?: (item: T, index: number) => void;
    onCellEnter?: (item: T, rowIndex: number, colIndex: number) => void;
    onCellLeave?: (item: T, rowIndex: number, colIndex: number) => void;
}

interface WithRowClick<T> {
    onRowClick: (item: T, index: number) => void;
    actionsColumnRenderer?: (item: T, handleItemClick: (item: T, index?: number) => void, index: number) => React.ReactNode | null;
}

interface WithoutRowClick<T> {
    onRowClick?: never;
    actionsColumnRenderer?: (item: T) => React.ReactNode | null;
}

type ColumnManagerProps<T, P = void> = withoutColumnManager<T, P> | withColumnManager<T, P>;
type RowClickProps<T> = WithRowClick<T> | WithoutRowClick<T>;

type IProps<T, P = void> = BaseProps<T, P> & ColumnManagerProps<T, P> & RowClickProps<T>;

function isWithRowClick<T, P = void>(props: IProps<T, P>): props is BaseProps<T, P> & WithRowClick<T> {
    return (props as WithRowClick<T>).hasOwnProperty('onRowClick');
}

export default function DataTable<T, P = void>(props: IProps<T, P>) {
    type Props = KeyOrNothing<P> | keyof T;

    const gettext = props.gettext;
    const [filterInputValues, setFilterInputValues] = React.useState<{
        [key in Props]?: string;
    }>(props.filters ? props.filters : {});
    const actionColumnElements: HTMLElement[] = [];
    const [actionsColumnWidth, setActionsColumnWidth] = React.useState(0);

    const onSortingChange = (newSorting: Domain.Sorting<Props>): void => {
        if (props.onSortingChange) {
            props.onSortingChange(newSorting);
        }
    };

    const onVisibleColumnsChange = (newVisibleColumns: Props[]): void => {
        if (props.onVisibleColumnsChange) {
            props.onVisibleColumnsChange(newVisibleColumns);
        }
    };
    const onSortableColumnsChange = (newSortableColumns: Props[]): void => {
        if (props.onSortableColumnsChange) {
            props.onSortableColumnsChange(newSortableColumns);
        }
    };
    const handleSortGizmoClick = (fieldName: Props) => {
        if (!props.sorting) {
            return;
        }

        if (props.sorting.field !== fieldName) {
            onSortingChange({ field: fieldName, direction: 'ascending' });
        } else if (props.sorting.direction === 'ascending') {
            onSortingChange({ field: props.sorting.field, direction: 'descending' });
        } else {
            onSortingChange({ field: props.sorting.field, direction: 'ascending' });
        }
    };

    const handleRowClick = (event: React.MouseEvent, item: T, index: number) => {
        if (props.onRowClick) {
            event.preventDefault();

            props.onRowClick(item, index);
        }
    };

    const handleRowEnter = (item: T, index: number) => {
        if (props.onRowEnter) {
            props.onRowEnter(item, index);
        }
    };

    const handleRowLeave = (item: T, index: number) => {
        if (props.onRowLeave) {
            props.onRowLeave(item, index);
        }
    };

    const handleCellEnter = (item: T, rowIndex: number, colIndex: number) => {
        if (props.onCellEnter) {
            props.onCellEnter(item, rowIndex, colIndex);
        }
    };

    const handleCellLeave = (item: T, rowIndex: number, colIndex: number) => {
        if (props.onCellLeave) {
            props.onCellLeave(item, rowIndex, colIndex);
        }
    };

    const columnIsVisible = (column: DataTableColumn<T, P>) => {
        return !props.showColumnManager || !column.toggleable || !props.visibleColumns || props.visibleColumns.indexOf(column.key) > -1;
    };

    const atLeastOneFilterIsColumn = () => {
        let isColumn = false;
        if (props.filters) {
            Object.keys(props.filters).map(key => {
                if (props.columns.find(column => column && column.key === key)) {
                    isColumn = true;
                }
            });
        }
        return isColumn;
    };
    const sortableColumns =
        props.sortableColumns || ((props.columns.filter(Boolean) as DataTableColumn<T, P>[]).map(column => column.key) as any);
    const visibleColumns =
        props.visibleColumns || ((props.columns.filter(Boolean) as DataTableColumn<T, P>[]).map(column => column.key) as any);
    const renderTableListViewMode = () => {
        return props.data.map((item, index) => {
            const itemKey = props.getItemKey(item, index);
            const itemTestId = props.getItemDataTestId ? props.getItemDataTestId(item, index) : itemKey;
            return (
                <Row
                    key={itemKey}
                    refCallback={element => {
                        if (
                            element &&
                            element.parentElement &&
                            element.parentElement.parentElement &&
                            props.itemIsActive &&
                            props.itemIsActive(item, index)
                        ) {
                            if (
                                element.parentElement.parentElement.scrollTop > element.offsetTop ||
                                element.parentElement.parentElement.scrollTop +
                                    element.parentElement.parentElement.offsetHeight -
                                    element.offsetHeight <
                                    element.offsetTop
                            ) {
                                element.parentElement.parentElement.scrollTop = element.offsetTop;
                            }
                        }
                    }}
                    data-test-id={`dataTable-${itemTestId}`}
                    onClick={e => handleRowClick(e, item, index)}
                    onMouseEnter={() => handleRowEnter(item, index)}
                    onMouseLeave={() => handleRowLeave(item, index)}
                    className={classNames(
                        props.itemIsActive && props.itemIsActive(item, index) ? 'active' : undefined,
                        props.extraItemClassFn ? props.extraItemClassFn(item, index) : undefined,
                    )}
                    vAlign={props.rowsVAlign}
                >
                    {(props.columns.filter(Boolean) as DataTableColumn<T, P>[])
                        .sort((a, b) => sortableColumns.indexOf(a.key) - sortableColumns.indexOf(b.key))
                        .map((column, colIndex) => {
                            if (columnIsVisible(column)) {
                                const style: React.CSSProperties = {};

                                if (column.width) {
                                    style.width = column.width;
                                }

                                if (column.minWidth) {
                                    style.minWidth = column.minWidth;
                                }

                                if (column.maxWidth) {
                                    style.maxWidth = column.maxWidth;
                                }

                                if (column.whiteSpace) {
                                    style.whiteSpace = column.whiteSpace as React.CSSProperties['whiteSpace'];
                                }

                                return (
                                    <Col
                                        key={column.key as string}
                                        style={style}
                                        hAlign={column.alignRight ? 'end' : 'start'}
                                        onClick={event => {
                                            if (column.preventClickDefault) {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            }
                                        }}
                                        onMouseEnter={() => handleCellEnter(item, index, colIndex)}
                                        onMouseLeave={() => handleCellLeave(item, index, colIndex)}
                                    >
                                        {column.render(item)}
                                    </Col>
                                );
                            }

                            return null;
                        })}

                    {props.actionsColumnRenderer ? (
                        <Col
                            width="1%"
                            ref={element => {
                                if (element) {
                                    const elementBox = element.getBoundingClientRect();
                                    setActionsColumnWidth(elementBox.width);
                                }
                            }}
                            style={{
                                pointerEvents: 'none',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                            }}
                        >
                            <div
                                style={{
                                    opacity: 0,
                                }}
                            >
                                {isWithRowClick(props)
                                    ? props.actionsColumnRenderer(item, props.onRowClick, index)
                                    : props.actionsColumnRenderer(item)}
                            </div>
                        </Col>
                    ) : null}

                    {props.actionsColumnRenderer ? (
                        <Col
                            width="1%"
                            style={{
                                position: 'absolute',
                                left: '0px',
                                opacity: 0,
                                paddingLeft: '5px',
                                paddingRight: '5px',
                            }}
                            data-test-id="dataTable-action-buttons"
                            ref={element => {
                                if (element) {
                                    actionColumnElements.push(element);
                                }
                            }}
                        >
                            {isWithRowClick(props)
                                ? props.actionsColumnRenderer(item, props.onRowClick, index)
                                : props.actionsColumnRenderer(item)}
                        </Col>
                    ) : null}
                </Row>
            );
        });
    };

    const renderTableListGridMode = () => {
        return (
            <Row
                className={styles.DataTableGridContainer}
                key="container"
            >
                <Col
                    style={{
                        width: '100%',
                    }}
                >
                    <DataGrid
                        items={props.data}
                        getItemKey={props.getItemKey}
                        getItemDataTestId={props.getItemDataTestId}
                        itemRenderer={item => (props.gridItemRenderer ? props.gridItemRenderer(item) : null)}
                        onItemClick={(e, item, index) => handleRowClick(e, item, index)}
                        itemIsActive={props.itemIsActive}
                    />
                </Col>
            </Row>
        );
    };

    const headerColumns: TableHeaderItem[] = [];
    const toggleColumnsOptions: {
        label: React.ReactNode;
        value: Props;
    }[] = [];

    const allLabels: React.ReactNode[] = [];
    (props.columns.filter(Boolean) as DataTableColumn<T, P>[])
        .sort((a, b) => sortableColumns.indexOf(a.key) - sortableColumns.indexOf(b.key))
        .forEach(column => {
            let label = column.label;
            let filter;

            if (column.filterable) {
                filter = column.customFilter ? (
                    column.customFilter
                ) : (
                    <DataTableFilterGizmo
                        gettext={gettext}
                        inputValue={filterInputValues[column.key]}
                        onApply={newInputValue => {
                            if (props.onFiltersChange) {
                                if (newInputValue !== undefined) {
                                    setFilterInputValues({
                                        ...filterInputValues,
                                        [column.key]: newInputValue,
                                    });
                                    props.onFiltersChange({
                                        ...props.filters,
                                        [column.key]: newInputValue,
                                    });
                                } else {
                                    props.onFiltersChange({
                                        ...props.filters,
                                        [column.key]: filterInputValues[column.key],
                                    });
                                }
                            }
                        }}
                        className={column.filterClassName}
                        filterName={column.key}
                        filterOptions={column.filterOptions}
                        filterOptionsPlaceholder={column.filterOptionsPlaceholder}
                        filterSearchProvider={column.filterSearchProvider}
                        filterSearchPlaceholder={column.filterSearchPlaceholder}
                        multiSelect={column.multiSelect}
                        dateTime={column.dateTime}
                    />
                );
            }
            if (column.customSorting && column.sortable) {
                label = (
                    <span className={styles.FilterSortContainer}>
                        {column.customSorting} {filter}
                    </span>
                );
            } else if (props.sorting && column.sortable) {
                label = (
                    <span className={styles.FilterSortContainer}>
                        <DataTableSortGizmo<Props>
                            label={column.label}
                            field={column.key}
                            onClick={handleSortGizmoClick}
                            sorting={props.sorting}
                        />
                        {filter}
                    </span>
                );
            } else {
                label = (
                    <span className={styles.FilterSortContainer}>
                        {label}
                        {filter}
                    </span>
                );
            }

            if (props.viewMode !== 'grid') {
                if (columnIsVisible(column)) {
                    headerColumns.push({
                        label,
                        width: column.width,
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                    });
                }
            } else if (column.sortable || column.filterable) {
                allLabels.push(
                    <span
                        key={column.key as string}
                        className="mr-20"
                    >
                        {label}
                    </span>,
                );
            }

            if (props.showColumnManager && column.toggleable) {
                toggleColumnsOptions.push({
                    label: column.plainTextLabel !== undefined ? column.plainTextLabel : column.label,
                    value: column.key,
                });
            }
        });

    if (props.viewMode === 'grid') {
        headerColumns.push({
            label: allLabels,
            width: '100%',
        });
    } else if (props.actionsColumnRenderer) {
        headerColumns.push({
            label: '',
            width: '1%',
        });
    }

    return (
        <React.Fragment>
            {(props.showColumnManager && props.viewMode !== 'grid') ||
            props.extraTopActions ||
            props.showToggleViewMode ||
            props.topInfo ||
            (props.showFilterPills && props.filters) ? (
                <Container
                    fullWidth={true}
                    className="mt-3 mb-13"
                    gutter={14}
                >
                    {props.topInfo ? (
                        <Row vAlign={props.topActionsVAlign ? props.topActionsVAlign : 'center'}>
                            <Col hAlign="start">{props.topInfo}</Col>
                        </Row>
                    ) : null}

                    <Row
                        vAlign={props.topActionsVAlign ? props.topActionsVAlign : 'center'}
                        wrap={true}
                    >
                        {props.showFilterPills && props.filters && atLeastOneFilterIsColumn() ? (
                            <Col hAlign="start">
                                <DataTableFilterPills<T, P>
                                    gettext={gettext}
                                    filters={props.filters}
                                    labelRenderers={
                                        props.showFilterPills ? (props.showFilterPills === true ? [] : props.showFilterPills) : []
                                    }
                                    columns={props.columns}
                                    onPillRemove={async (key: Props | undefined) => {
                                        if (key === undefined) {
                                            setFilterInputValues({});
                                        } else {
                                            setFilterInputValues({
                                                ...filterInputValues,
                                                [key]: undefined,
                                            });
                                        }

                                        if (props.onFiltersChange) {
                                            if (key) {
                                                props.onFiltersChange({
                                                    ...props.filters,
                                                    [key]: undefined,
                                                });
                                            } else {
                                                props.onFiltersChange({});
                                            }
                                        }
                                        const column = props.columns.find(
                                            column => column && column.key === key && column.customRemoveFilter,
                                        );
                                        if (column && column.customRemoveFilter) {
                                            column.customRemoveFilter();
                                        }
                                    }}
                                />
                            </Col>
                        ) : null}

                        {props.extraTopActions && !props.extraTopActionsAreCols ? (
                            <Col hAlign="end">{props.extraTopActions}</Col>
                        ) : props.extraTopActions && props.extraTopActionsAreCols ? (
                            props.extraTopActions
                        ) : null}

                        {props.showToggleViewMode ? (
                            <Col hAlign="end">
                                <Card
                                    className={styles.ToggleDataTableViewMode}
                                    elevated={true}
                                >
                                    <Tooltip text={gettext('List view')}>
                                        <Button
                                            variant="plain"
                                            variantSize="xs"
                                            className="px-0 mr-11"
                                            onClick={() => (props.setViewMode ? props.setViewMode('list') : false)}
                                            startIcon={
                                                <Icon
                                                    type="action_list"
                                                    iconSize="m"
                                                    color={props.viewMode !== 'grid' ? color.Primary.Blue : color.Grey.Dark2}
                                                />
                                            }
                                        />
                                    </Tooltip>

                                    <Tooltip text={gettext('Grid view')}>
                                        <Button
                                            variant="plain"
                                            variantSize="xs"
                                            className="px-0"
                                            onClick={() => (props.setViewMode ? props.setViewMode('grid') : false)}
                                            startIcon={
                                                <Icon
                                                    type="action_table"
                                                    iconSize="m"
                                                    color={props.viewMode === 'grid' ? color.Primary.Blue : color.Grey.Dark2}
                                                />
                                            }
                                        />
                                    </Tooltip>
                                </Card>
                            </Col>
                        ) : null}

                        {props.showColumnManager && props.viewMode !== 'grid' ? (
                            <Col hAlign={props.alignToggleColumnsToLeft ? 'start' : 'end'}>
                                <ManageColumns
                                    gettext={props.gettext}
                                    toggleColumnsOptions={toggleColumnsOptions}
                                    className={styles.DataTableTopActionsDropdown}
                                    onVisibleColumnsChange={onVisibleColumnsChange as any}
                                    visibleColumns={visibleColumns}
                                    onSortableColumnsChange={onSortableColumnsChange as any}
                                    sortableColumns={sortableColumns}
                                />
                            </Col>
                        ) : null}
                    </Row>
                </Container>
            ) : null}
            <Card
                vSpacing="none"
                hSpacing="none"
                elevated={true}
                className={props.cardClassName}
            >
                <Table
                    className={`${styles.DataTable} ${props.className}`}
                    containerClassName={styles.DataTableContainer}
                    header={headerColumns}
                    body={props.viewMode !== 'grid' ? renderTableListViewMode() : renderTableListGridMode()}
                    disableRowHover={props.viewMode === 'grid'}
                    extra={
                        props.isLoading ? (
                            <div className={styles.DataTableIsLoadingOverlay}>
                                <Preloader
                                    width="100%"
                                    height="100%"
                                    primary={true}
                                />
                            </div>
                        ) : null
                    }
                    onTableScroll={(tableWidth, maxLeftScroll, leftScroll) => {
                        actionColumnElements.forEach(element => {
                            element.style.opacity = '1';
                            element.style.left = Math.min(tableWidth + leftScroll, Math.floor(maxLeftScroll)) - actionsColumnWidth + 'px';
                            element.style.height = element.parentElement ? element.parentElement.clientHeight + 'px' : '0px';
                        });
                    }}
                    visibleOverflow={props.visibleOverflow}
                />
            </Card>
            {props.pagination ? (
                <Container
                    fullWidth={true}
                    className="my-22"
                >
                    <Row className={styles.DataTablePaginationRow}>
                        <Col>
                            <Pagination
                                maxVisiblePages={5}
                                {...props.pagination}
                                gettext={gettext}
                            />
                        </Col>
                    </Row>
                </Container>
            ) : null}
        </React.Fragment>
    );
}
