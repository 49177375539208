import * as React from 'react';
import { useSelector } from 'react-redux';

import { useThunkDispatch, useGettext, CmsItem, I18n } from 'data-store';
import { color, Row, Col, Grid, LinkButton, Tx } from 'ui-components';
import { browserStorage } from 'utils';

import PublicAnnouncementItem from './PublicAnnouncementItem';

import styles from '../PublicAnnouncementsOverview.scss';

const READ_PUBLIC_ANNOUNCEMENTS = 'ReadPublicAnnouncements';

export default function PublicAnnouncementsList(props: { className?: string; pageSize: number }) {
    const dispatch = useThunkDispatch();
    const { gettext } = useGettext();

    const publicAnnouncements = useSelector(CmsItem.publicAnnouncementsOverviewState.selectPublicAnnouncements);
    const readPublicAnnouncements = useSelector(CmsItem.publicAnnouncementsOverviewState.selectReadPublicAnnouncements);
    const locale = useSelector(I18n.selectCurrentLocale);

    const setAnnouncementAsRead = async (announcementId: string) => {
        if (!readPublicAnnouncements.includes(announcementId) && publicAnnouncements) {
            const updatedReadAnnouncements = [...readPublicAnnouncements, announcementId];
            browserStorage.setItem(READ_PUBLIC_ANNOUNCEMENTS, updatedReadAnnouncements);
            await dispatch(CmsItem.publicAnnouncementsOverviewState.setReadPublicAnnouncements(updatedReadAnnouncements));
            await dispatch(
                CmsItem.publicAnnouncementsOverviewState.setUnreadPublicAnnouncementsCount(
                    publicAnnouncements.total - updatedReadAnnouncements.length,
                ),
            );
        }
    };

    const onPageInit = React.useCallback(async () => {
        await dispatch(CmsItem.publicAnnouncementsOverviewState.setPaginationSize(props.pageSize));
        await dispatch(CmsItem.publicAnnouncementsOverviewState.loadPublicAnnouncements());
    }, []);

    React.useEffect(() => {
        onPageInit();
    }, [locale]);

    const readAnnouncements = React.useMemo(() => {
        const readAnnouncements = browserStorage.getItem<string[]>(READ_PUBLIC_ANNOUNCEMENTS)?.value;
        return publicAnnouncements.items.filter(publicAnnouncement => readAnnouncements?.includes(publicAnnouncement.cmsItemId));
    }, [publicAnnouncements]);

    const unreadAnnouncements = React.useMemo(() => {
        const readAnnouncements = browserStorage.getItem<string[]>(READ_PUBLIC_ANNOUNCEMENTS)?.value;
        return publicAnnouncements.items.filter(publicAnnouncement => !readAnnouncements?.includes(publicAnnouncement.cmsItemId));
    }, [publicAnnouncements]);

    return (
        <Grid
            gutter={24}
            cols={10}
            className={styles.PublicAnnouncementsWrapper}
        >
            <Row>
                <Col
                    span={styles.IsWidget ? 10 : 7}
                    spanSm={10}
                    hAlign="start"
                    className={styles.PublicAnnouncementsItems}
                >
                    <Row className={`${styles.PublicAnnouncementListHeader} px-25 py-10 mb-10 ${color.BG.Primary.Blue}`}>
                        <Col>
                            <Tx
                                level="heading-2"
                                className={`mb-10 ${color.White}`}
                            >
                                {gettext('Lochting News')}
                            </Tx>
                        </Col>
                        <Col hAlign="center">
                            <LinkButton
                                size="xs"
                                variant="secondary"
                                to="/dashboard/lochting-news"
                            >
                                {gettext('See all Lochting news')}
                            </LinkButton>
                        </Col>
                    </Row>

                    <Row className={styles.SteamerScrollableList}>
                        {unreadAnnouncements.map(publicAnnouncement => (
                            <PublicAnnouncementItem
                                publicAnnouncement={publicAnnouncement}
                                onMouseEnter={() => setAnnouncementAsRead(publicAnnouncement.cmsItemId)}
                                key={publicAnnouncement.cmsItemId}
                                isUnread={!readPublicAnnouncements.includes(publicAnnouncement.cmsItemId)}
                                allowClick={false}
                            />
                        ))}

                        {readAnnouncements.slice(0, props.pageSize).map(publicAnnouncement => (
                            <PublicAnnouncementItem
                                publicAnnouncement={publicAnnouncement}
                                onMouseEnter={() => setAnnouncementAsRead(publicAnnouncement.cmsItemId)}
                                key={publicAnnouncement.cmsItemId}
                                isUnread={!readPublicAnnouncements.includes(publicAnnouncement.cmsItemId)}
                                allowClick={false}
                            />
                        ))}
                        {readAnnouncements?.length === 0 && unreadAnnouncements?.length === 0 && (
                            <Tx>{gettext('No available news at the moment')}</Tx>
                        )}
                    </Row>
                </Col>
            </Row>
        </Grid>
    );
}
