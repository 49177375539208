import * as React from 'react';

import { Button, Icon, color } from 'ui-components';

import useVideoCallTrigger from './useVideoCallTrigger';

import styles from './VideoCall.scss';

export function VideoCallTrigger(props: { className?: string }) {
    const trigger = useVideoCallTrigger();

    if (!trigger.isEnabled) {
        return null;
    }

    return (
        <div
            className={`${props.className} ${styles.VideoCallTrigger} DropdownHeader`}
            ref={element => trigger.context.setTriggerElement(element || undefined)}
        >
            <Button
                variant="secondary"
                variantSize="xs"
                onClick={event => {
                    trigger.handleClickInsidePanel(event);
                    trigger.context.setPanelIsOpened(!trigger.context.panelIsOpened);
                }}
                startIcon={
                    <Icon
                        type="action_phone"
                        color={color.Black}
                        className="ml--5 mr--5"
                    />
                }
            >
                <span
                    className={`${styles.RegistrationStatus} ${trigger.context.registrationStatus === 'registered' ? styles.Registered : styles.Registering}`}
                />
            </Button>
        </div>
    );
}
