import React from 'react';

import moment from 'moment';

import color from '@/color';
import { Color } from '@/colorPicker';

import styles from './CalendarEvent.scss';
export interface ICalendarEvent {
    id: string;
    name: string;
    startDate: string;
    duration: number;
    color?: string;
    status: 'active' | 'canceled';
    onClick?: () => void;
}
export const CalendarEvent = ({
    event,
    height,
    eventWidth = 100,
    eventLeft = 0,
    startPosition = 0,
    content,
    className = '',
}: {
    event: ICalendarEvent;
    height: number;
    eventWidth?: number;
    eventLeft?: number;
    startPosition?: number;
    content?: React.ReactNode;
    className?: string;
}) => {
    const defaultColor = '#e3f2fd';
    const eventTime = `${moment.utc(event.startDate).format('HH:mm')} - ${moment.utc(event.startDate).add(event.duration, 'minutes').format('HH:mm')}`;
    const eventColor = new Color(event.color || defaultColor);
    const isDarkBackground = eventColor.isDark();
    const textColor = isDarkBackground ? '#FFFFFF' : color.Grey.Dark1;
    const borderColor = isDarkBackground ? eventColor.brighten(40).toHex() : eventColor.darken(40).toHex();

    return (
        <div
            className={`${styles.CalendarEvent} ${event.status ? event.status : ''} ${className}`}
            onClick={event.onClick}
            style={{
                cursor: event.onClick ? 'pointer' : 'default',
                backgroundColor: event.color || defaultColor,
                height: `${height}px`,
                top: `${startPosition}px`,
                width: `${eventWidth}%`,
                left: `${eventLeft}%`,
                borderLeft: `2px solid #${borderColor}`,
                color: textColor,
            }}
        >
            {content ? (
                content
            ) : (
                <>
                    <span className={styles.eventText}>
                        {event.name}
                        {height < 39 && `, ${eventTime}`}
                    </span>
                    {height > 38 && <span className={styles.eventTime}>{eventTime}</span>}
                </>
            )}
        </div>
    );
};
