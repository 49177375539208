import * as React from 'react';

import { Preloader } from 'ui-components';

export default function BasicPageHeader() {
    return (
        <React.Fragment>
            <Preloader
                primary={true}
                width={60}
                varyWidth={300}
                height={25}
                className="mt-7"
            />
            <Preloader
                width={500}
                varyWidth={800}
                height={15}
                className="mt-23 mb-26"
            />
        </React.Fragment>
    );
}
