import * as React from 'react';

import { Button } from '@/button';
import { Card, CardItem } from '@/card';
import { createPortal } from '@/core';
import { Container, Row, Col } from '@/layout';
import { Tx } from '@/typography';

import styles from './DialogModal.scss';

export default function DialogModal(props: {
    confirmTitle?: React.ReactNode;
    confirmMessage: React.ReactNode;
    onConfirm: () => void;
    close: () => void;
    cancelLabel: React.ReactNode;
    confirmLabel: React.ReactNode;
    disabled?: boolean;
}) {
    return createPortal(
        <div
            className={styles.DialogModal}
            onClick={event => {
                event.preventDefault();
                props.close();
            }}
        >
            <Card
                elevated={true}
                className={styles.DialogModalBody}
            >
                {props.confirmTitle ? (
                    <CardItem>
                        <Tx level="heading-5">{props.confirmTitle}</Tx>
                    </CardItem>
                ) : null}

                <CardItem>
                    <Tx as="p">{props.confirmMessage}</Tx>
                </CardItem>

                <CardItem>
                    <Container
                        gutter={14}
                        fullWidth={true}
                    >
                        <Row>
                            <Col hAlign="start">
                                <Button
                                    variant="tertiary"
                                    variantSize="s"
                                    onClick={event => {
                                        props.close();
                                        event.stopPropagation();
                                    }}
                                >
                                    {props.cancelLabel}
                                </Button>
                            </Col>

                            <Col hAlign="end">
                                <Button
                                    variant="primary"
                                    variantSize="s"
                                    onClick={event => {
                                        props.onConfirm();
                                        event.stopPropagation();
                                    }}
                                >
                                    {props.confirmLabel}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </CardItem>
            </Card>
        </div>,
    );
}
