import React from 'react';

import moment, { Moment } from 'moment';

import { Button } from '@/button';
import { Card } from '@/card';
import { Dropdown } from '@/core';
import { Icon } from '@/icon';
import { Container, Row, Col } from '@/layout';

import { CalendarDayLabel } from './CalendarDayLabel';
import { CalendarEvent, ICalendarEvent } from './CalendarEvent';

import styles from './MonthlyView.scss';
interface MonthlyViewProps {
    currentDate: Moment;
    events: ICalendarEvent[];
    renderViewMoreAction?: (selectedDate: string) => JSX.Element | null;
    gettext: (text: string) => string;
    onDateClick?: (date: Moment) => void;
}

const getDaysInMonth = (currentDate: Moment): Moment[] => {
    const startOfMonth = currentDate.clone().startOf('month');
    const endOfMonth = currentDate.clone().endOf('month');
    const startOfCalendar = startOfMonth.clone().startOf('isoWeek');
    const endOfCalendar = endOfMonth.clone().endOf('isoWeek');

    const totalDays = endOfCalendar.diff(startOfCalendar, 'days') + 1;
    return Array.from({ length: totalDays }, (_, index) => startOfCalendar.clone().add(index, 'day'));
};

const MonthlyView = ({ currentDate, events, renderViewMoreAction, gettext, onDateClick }: MonthlyViewProps) => {
    const currentDayRef = React.useRef<HTMLDivElement | null>(null);

    const daysInMonth = getDaysInMonth(currentDate);
    const getEventsForDay = (date: Moment) => {
        return events
            .filter(event => moment(event.startDate).isSame(date, 'day'))
            .sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));
    };

    React.useEffect(() => {
        if (currentDayRef.current) {
            currentDayRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []);

    // Add a day to the end of the week to make it start on Monday
    const weekdaysShort = [...moment.weekdaysShort().slice(1), moment.weekdaysShort()[0]];
    return (
        <div
            className={styles.MonthlyView}
            data-test-id="calendar-schedule-monthly-view"
        >
            <div className={styles.header}>
                {weekdaysShort.map(day => (
                    <div
                        key={day}
                        className={styles.dayHeader}
                    >
                        {day}
                    </div>
                ))}
            </div>
            <div className={styles.body}>
                {daysInMonth.map((date, index) => {
                    const dropdown = React.createRef<Dropdown>();

                    const eventsForDay = getEventsForDay(date);
                    const isCurrentMonth = date.month() === currentDate.month();
                    const isSameDay = date.isSame(moment(), 'day');
                    const hasMoreActions = renderViewMoreAction && renderViewMoreAction(date.format('YYYY-MM-DD')) !== null;

                    return (
                        <div
                            key={index}
                            className={`${styles.dayCell} ${isCurrentMonth ? '' : styles.adjacent}`}
                            ref={isSameDay ? currentDayRef : null}
                        >
                            <CalendarDayLabel
                                date={date}
                                hideDayName
                                className="mt-8 ml-3"
                                onDateClick={() => (onDateClick ? onDateClick(date) : null)}
                            />
                            <div className={styles.events}>
                                {eventsForDay.slice(0, 2).map((event, index) => (
                                    <CalendarEvent
                                        className={styles.CalendarEvent}
                                        key={index}
                                        event={event}
                                        height={40}
                                    />
                                ))}
                                {eventsForDay.length > 2 && (
                                    <Dropdown
                                        ref={dropdown}
                                        anchorTop
                                        headerRenderer={() => (
                                            <Button
                                                variant="plain"
                                                variantSize="xs"
                                                fullWidth
                                            >
                                                {eventsForDay.length - 2}
                                                {gettext(' more')}
                                            </Button>
                                        )}
                                        body={
                                            <Card
                                                elevated={true}
                                                className={styles.VieMoreDialogCard}
                                            >
                                                <Container>
                                                    <Row vAlign="center">
                                                        <CalendarDayLabel date={date} />
                                                        <Col hAlign="end">
                                                            <Button
                                                                variant="plain"
                                                                variantSize="xs"
                                                                onClick={() => dropdown.current?.close(true)}
                                                                startIcon={<Icon type="action_remove" />}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className={styles.ViewMoreEventsContainer}>
                                                        <Col fullWidth>
                                                            {eventsForDay.map((event, index) => (
                                                                <CalendarEvent
                                                                    className={styles.CalendarEvent}
                                                                    key={index}
                                                                    event={event}
                                                                    height={40}
                                                                />
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-16">
                                                        <Col hAlign={hasMoreActions ? 'start' : 'end'}>
                                                            <Button
                                                                variantSize="s"
                                                                onClick={() => dropdown.current?.close(true)}
                                                                variant={hasMoreActions ? 'plain' : 'tertiary'}
                                                            >
                                                                {gettext('Close')}
                                                            </Button>
                                                        </Col>
                                                        {renderViewMoreAction && (
                                                            <Col hAlign="end">{renderViewMoreAction(date.format('YYYY-MM-DD'))}</Col>
                                                        )}
                                                    </Row>
                                                </Container>
                                            </Card>
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default MonthlyView;
